<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['description_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.description.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.description.editControl.placeholder}}"
            [ngStyle]="row.cells.description.editControl.styles.style"
            [ngClass]="row.cells.description.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allergen">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.allergen.displayControl.styles.style"
          [ngClass]="row.cells.allergen.displayControl.styles.classes">{{row.cells.allergen.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-custom_udf_allergen_dd_single 
        data-cy="selector"
        [type]="row.cells.allergen.editControl.type"
        [formControl]="row.formGroup.controls['allergen_edit']"
        (displayTextChange)="row.cells.allergen.editControl.displayText=$event"
        [placeholder]="row.cells.allergen.editControl.placeholder"
        [styles]="row.cells.allergen.editControl.styles"
    >
    </app-custom_udf_allergen_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="first_step_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.first_step_location.displayControl.styles.style"
          [ngClass]="row.cells.first_step_location.displayControl.styles.classes">{{row.cells.first_step_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['first_step_location_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.first_step_location.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.first_step_location.editControl.placeholder}}"
            [ngStyle]="row.cells.first_step_location.editControl.styles.style"
            [ngClass]="row.cells.first_step_location.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_organic">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_organic_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_organic.displayControl.styles.style"
                  [ngClass]="row.cells.is_organic.displayControl.styles.classes">{{row.cells.is_organic.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_organic_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_organic.editControl.styles.style"
                  [ngClass]="row.cells.is_organic.editControl.styles.classes">{{row.cells.is_organic.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="temperature">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.temperature.displayControl.styles.style"
          [ngClass]="row.cells.temperature.displayControl.styles.classes">{{row.cells.temperature.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['temperature_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.temperature.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.temperature.editControl.placeholder}}"
            [ngStyle]="row.cells.temperature.editControl.styles.style"
            [ngClass]="row.cells.temperature.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="weight_ordered">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.weight_ordered.displayControl.styles.style"
          [ngClass]="row.cells.weight_ordered.displayControl.styles.classes">{{row.cells.weight_ordered.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['weight_ordered_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.weight_ordered.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.weight_ordered.editControl.placeholder}}"
            [ngStyle]="row.cells.weight_ordered.editControl.styles.style"
            [ngClass]="row.cells.weight_ordered.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="trade_partner_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.trade_partner_number.displayControl.styles.style"
          [ngClass]="row.cells.trade_partner_number.displayControl.styles.classes">{{row.cells.trade_partner_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['trade_partner_number_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.trade_partner_number.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.trade_partner_number.editControl.placeholder}}"
            [ngStyle]="row.cells.trade_partner_number.editControl.styles.style"
            [ngClass]="row.cells.trade_partner_number.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="cube_ordered">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.cube_ordered.displayControl.styles.style"
          [ngClass]="row.cells.cube_ordered.displayControl.styles.classes">{{row.cells.cube_ordered.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['cube_ordered_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.cube_ordered.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.cube_ordered.editControl.placeholder}}"
            [ngStyle]="row.cells.cube_ordered.editControl.styles.style"
            [ngClass]="row.cells.cube_ordered.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipment">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.shipment.displayControl.styles.style"
          [ngClass]="row.cells.shipment.displayControl.styles.classes">{{row.cells.shipment.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['shipment_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.shipment.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.shipment.editControl.placeholder}}"
            [ngStyle]="row.cells.shipment.editControl.styles.style"
            [ngClass]="row.cells.shipment.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="require_manufacture_date">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['require_manufacture_date_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.require_manufacture_date.displayControl.styles.style"
                  [ngClass]="row.cells.require_manufacture_date.displayControl.styles.classes">{{row.cells.require_manufacture_date.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['require_manufacture_date_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.require_manufacture_date.editControl.styles.style"
                  [ngClass]="row.cells.require_manufacture_date.editControl.styles.classes">{{row.cells.require_manufacture_date.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="require_expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['require_expiration_date_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.require_expiration_date.displayControl.styles.style"
                  [ngClass]="row.cells.require_expiration_date.displayControl.styles.classes">{{row.cells.require_expiration_date.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['require_expiration_date_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.require_expiration_date.editControl.styles.style"
                  [ngClass]="row.cells.require_expiration_date.editControl.styles.classes">{{row.cells.require_expiration_date.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="require_inspection">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['require_inspection_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.require_inspection.displayControl.styles.style"
                  [ngClass]="row.cells.require_inspection.displayControl.styles.classes">{{row.cells.require_inspection.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['require_inspection_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.require_inspection.editControl.styles.style"
                  [ngClass]="row.cells.require_inspection.editControl.styles.classes">{{row.cells.require_inspection.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="days_remaining_threshold">

    <ng-template gridCellDisplayControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['days_remaining_threshold_display']"
            matInput
            numberBox
            [format]="row.cells.days_remaining_threshold.displayControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.days_remaining_threshold.displayControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.days_remaining_threshold.displayControl.placeholder}}"
            [ngStyle]="row.cells.days_remaining_threshold.displayControl.styles.style"
            [ngClass]="row.cells.days_remaining_threshold.displayControl.styles.classes">
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['days_remaining_threshold_edit']"
            matInput
            numberBox
            [format]="row.cells.days_remaining_threshold.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.days_remaining_threshold.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.days_remaining_threshold.editControl.placeholder}}"
            [ngStyle]="row.cells.days_remaining_threshold.editControl.styles.style"
            [ngClass]="row.cells.days_remaining_threshold.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="secondary_full_pallet_qty">

    <ng-template gridCellDisplayControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['secondary_full_pallet_qty_display']"
            matInput
            numberBox
            [format]="row.cells.secondary_full_pallet_qty.displayControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.secondary_full_pallet_qty.displayControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.secondary_full_pallet_qty.displayControl.placeholder}}"
            [ngStyle]="row.cells.secondary_full_pallet_qty.displayControl.styles.style"
            [ngClass]="row.cells.secondary_full_pallet_qty.displayControl.styles.classes">
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['secondary_full_pallet_qty_edit']"
            matInput
            numberBox
            [format]="row.cells.secondary_full_pallet_qty.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.secondary_full_pallet_qty.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.secondary_full_pallet_qty.editControl.placeholder}}"
            [ngStyle]="row.cells.secondary_full_pallet_qty.editControl.styles.style"
            [ngClass]="row.cells.secondary_full_pallet_qty.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="validated">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['validated_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.validated.displayControl.styles.style"
                  [ngClass]="row.cells.validated.displayControl.styles.classes">{{row.cells.validated.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['validated_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.validated.editControl.styles.style"
                  [ngClass]="row.cells.validated.editControl.styles.classes">{{row.cells.validated.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allow_today_expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['allow_today_expiration_date_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.allow_today_expiration_date.displayControl.styles.style"
                  [ngClass]="row.cells.allow_today_expiration_date.displayControl.styles.classes">{{row.cells.allow_today_expiration_date.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['allow_today_expiration_date_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.allow_today_expiration_date.editControl.styles.style"
                  [ngClass]="row.cells.allow_today_expiration_date.editControl.styles.classes">{{row.cells.allow_today_expiration_date.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="remove_require_expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['remove_require_expiration_date_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.remove_require_expiration_date.displayControl.styles.style"
                  [ngClass]="row.cells.remove_require_expiration_date.displayControl.styles.classes">{{row.cells.remove_require_expiration_date.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['remove_require_expiration_date_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.remove_require_expiration_date.editControl.styles.style"
                  [ngClass]="row.cells.remove_require_expiration_date.editControl.styles.classes">{{row.cells.remove_require_expiration_date.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="damage">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.damage.displayControl.styles.style"
          [ngClass]="row.cells.damage.displayControl.styles.classes">{{row.cells.damage.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['damage_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.damage.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.damage.editControl.placeholder}}"
            [ngStyle]="row.cells.damage.editControl.styles.style"
            [ngClass]="row.cells.damage.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="serious_damage">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.serious_damage.displayControl.styles.style"
          [ngClass]="row.cells.serious_damage.displayControl.styles.classes">{{row.cells.serious_damage.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['serious_damage_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.serious_damage.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.serious_damage.editControl.placeholder}}"
            [ngStyle]="row.cells.serious_damage.editControl.styles.style"
            [ngClass]="row.cells.serious_damage.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="decay">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.decay.displayControl.styles.style"
          [ngClass]="row.cells.decay.displayControl.styles.classes">{{row.cells.decay.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['decay_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.decay.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.decay.editControl.placeholder}}"
            [ngStyle]="row.cells.decay.editControl.styles.style"
            [ngClass]="row.cells.decay.editControl.styles.classes"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
