import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Ultraship_ShellService, EModalSize, EToasterType, EToasterPosition } from './Ultraship.shell.service';
import { Ultraship_OperationService } from './Ultraship.operation.service';
import { Ultraship_DatasourceService } from './Ultraship.datasource.index';
import { Ultraship_FlowService } from './Ultraship.flow.index';
import { Ultraship_ReportService } from './Ultraship.report.index';
import { Ultraship_LocalizationService } from './Ultraship.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Ultraship.frontend.types'
import { $frontendTypes as $types} from './Ultraship.frontend.types' 

import { Ultraship_projects_configuration_dd_singleComponent } from './Ultraship.projects_configuration_dd_single.component'
import { Ultraship_projects_dd_singleComponent } from './Ultraship.projects_dd_single.component'


interface IUltraship_REMOVE_ultraship_project_configurations_grid_v2ComponentEntity {
configuration_id?: string, application_name?: string, user_name?: string, name?: string, value?: string, notes?: string, string_1?: string, string_2?: string, string_3?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, date_number?: number}

interface IUltraship_REMOVE_ultraship_project_configurations_grid_v2ComponentInParams {
  application_name?: string, user_name?: string, column_names?: { string_1?: string, string_2?: string, string_3?: string }, filter_values?: { string_1?: string, string_2?: string, string_3?: string }, name?: string}


class Ultraship_REMOVE_ultraship_project_configurations_grid_v2ComponentRowModel extends GridRowModel {
  grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component;
  entity: IUltraship_REMOVE_ultraship_project_configurations_grid_v2ComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    application_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    value_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    string_1_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    application_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['application_name_edit'] as DatexFormControl, null, false, 'ULTRASHIP')
      ),
    user_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['name_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    value: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['value_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    string_1: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['string_1_edit'] as DatexFormControl, null, false, '')
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, '')
      ),
    created_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modified_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modified_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }

  get $fields_value_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Ultraship_ShellService,
private datasources: Ultraship_DatasourceService,
private flows: Ultraship_FlowService,
private reports: Ultraship_ReportService,
private localization: Ultraship_LocalizationService,
private operations: Ultraship_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component, entity: IUltraship_REMOVE_ultraship_project_configurations_grid_v2ComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.configuration_id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component, entity?: IUltraship_REMOVE_ultraship_project_configurations_grid_v2ComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.configuration_id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.configuration_id;
    const inParams = {
      $keys:[$resultKey],
      application_name:  $grid.inParams.application_name ,
      user_name:  $grid.inParams.user_name ,
      name:  $grid.inParams.name ,
      full_text_search:  $grid.fullTextSearch ,
      string_1:  $grid.inParams.filter_values?.string_1 ,
      string_2:  $grid.inParams.filter_values?.string_2 ,
      string_3:  $grid.inParams.filter_values?.string_3 ,
    };
    const data = await this.datasources.Utilities.ds_configurations_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.configuration_id;
    (this.cells.application_name.displayControl as TextModel).text = $row.entity.application_name;
    (this.cells.application_name.editControl as TextBoxModel).reset($row.entity.application_name);
    (this.cells.user_name.displayControl as TextModel).text = $row.entity.user_name;
    (this.cells.name.displayControl as TextModel).text = $row.entity.name;
    (this.cells.name.editControl as SelectBoxModel).reset($row.entity.name);
    (this.cells.value.displayControl as TextModel).text = $row.entity.value;
    (this.cells.string_1.displayControl as TextModel).text = $row.entity.string_1;
    
    (this.cells.notes.displayControl as TextModel).text = $row.entity.notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.notes);
    (this.cells.created_by.displayControl as TextModel).text = $row.entity.created_by;
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.created_on?.toString();
    (this.cells.modified_by.displayControl as TextModel).text = $row.entity.modified_by;
    (this.cells.modified_on.displayControl as TextModel).text = $row.entity.modified_on?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Ultraship_REMOVE_ultraship_project_configurations_grid_v2ComponentRowModel,
  $grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component, 
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'REMOVE_ultraship_project_configurations_grid_v2.on_row_data_loaded');
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.created_on)) {
          $row.cells.created_on.displayControl.text = $utils.date.format($row.entity.created_on, format);
      }
      if ($utils.isDefined($row.entity.modified_on)) {
          $row.cells.modified_on.displayControl.text = $utils.date.format($row.entity.modified_on, format);
      }
  }
  
  
  /*
  let notes = $row.entity.notes;
  
  if ($utils.isDefined(notes)) {
      notes = (notes.length > 75) ? notes.substring(0, 75) : notes;
      $row.cells.notes.displayControl.text = notes;
  }*/
  
  let value = $row.entity.value;
  
  if ($utils.isDefined(value)) {
      value = (value.length > 75) ? value.substring(0, 75) : value;
      $row.cells.value.displayControl.text = value;
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: Ultraship_REMOVE_ultraship_project_configurations_grid_v2ComponentRowModel,
  $grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component, 
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'REMOVE_ultraship_project_configurations_grid_v2.on_save_existing_row');
  
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.name.editControl.value) &&
      $utils.isDefined($row.cells.value.editControl.value)
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Utilities.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  if ($row.cells.name.editControl.isChanged ||
      $row.cells.value.editControl.isChanged ||
      $row.cells.notes.editControl.isChanged ||
      $row.cells.string_1.editControl.isChanged ||
      $row.cells.application_name.editControl.isChanged) {
  
      let encrypt: boolean = $grid.filters.encrypt.control.value ?? false;
  
      let config_id: string = $row.entity?.configuration_id ?? "";
      let config_name: string = $row.cells.name.editControl.value;
      let config_value: string = $row.cells.value.editControl.value;
      let config_notes: string = $row.cells.notes.editControl.value;
      let string_1: string = $row.cells.string_1.editControl.value ?? $settings.Ultraship.Environment.toUpperCase();
      let application_name: string = $grid.inParams.application_name ?? $settings.Ultraship.ApplicationName.toUpperCase();
      let user_name: string = $grid.inParams.user_name ?? "";
  
      if (application_name === "") {
          application_name = $row.cells.application_name.editControl.value ?? "";
      }
  
      try {
  
          if (config_id === "" && application_name === "") {
              throw new Error("You need to specify the application to insert a configuration!")
          }
  
          let input = { configuration_id: config_id, name: config_name, value: config_value, notes: config_notes, user_name: user_name, string_1: string_1};
          let criteria = { application_name: application_name, encrypt: encrypt };
  
          if (config_id !== "") {
              await $flows.Utilities.configurations_update({
                  input: [input],
                  criteria: criteria
              })
          } else {
              await $flows.Utilities.configurations_write({
                  input: [input],
                  criteria: criteria
              })
          }
  
          $grid.refresh();
  
      } catch (error) {
          $shell.Utilities.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  }
  }
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: Ultraship_REMOVE_ultraship_project_configurations_grid_v2ComponentRowModel,
  $grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component, 
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'REMOVE_ultraship_project_configurations_grid_v2.on_init_new_row');
  /** Setting default values */
  //$row.cells.application_name.displayControl.text = $settings.Ultraship.ApplicationName?.toUpperCase() ;
  $row.cells.application_name.editControl.value = $settings.Ultraship.ApplicationName?.toUpperCase() ;
  $row.cells.name.editControl.value = $types.Ultraship.Configuration.ultraship_projects;
  $row.cells.string_1.editControl.value = $settings.Ultraship.Environment ?? 'TEST'
  
  
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: Ultraship_REMOVE_ultraship_project_configurations_grid_v2ComponentRowModel,
  $grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component, 
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'REMOVE_ultraship_project_configurations_grid_v2.on_save_new_row');
  
  if ($utils.isDefined($row.cells.name.editControl.value) == false) {
      $row.cells.name.editControl.value = $types.Ultraship.Configuration.ultraship_projects;
  }
  
  if ($utils.isDefined($row.cells.application_name.editControl.value) == false)  {
      $row.cells.application_name.editControl.value = $settings.Ultraship.ApplicationName.toUpperCase() ?? $row.cells.application_name.editControl.value;
  }
  
  if ($utils.isDefined($row.cells.string_1.editControl.value) == false) {
      $row.cells.string_1.editControl.value =  $settings.Ultraship.Environment.toUpperCase();
  }
  
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.name.editControl.value) &&
      $utils.isDefined($row.cells.value.editControl.value)  &&
      $utils.isDefined($row.cells.string_1.editControl.value)
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Utilities.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  
  let encrypt: boolean = $grid.filters.encrypt.control.value ?? false;
  
  let config_id: string = $utils.createGuid()
  let config_name: string = $row.cells.name.editControl.value;
  let config_value: string = $row.cells.value.editControl.value;
  let config_notes: string = $row.cells.notes.editControl.value;
  let string_1: string = $row.cells.string_1.editControl.value ?? $settings.Ultraship.Environment.toUpperCase();
  let application_name: string = $row.cells.application_name.editControl.value;
  let user_name: string = $grid.inParams.user_name ?? "";
  
  try {
  
      if (config_id === "" && application_name === "") {
          throw new Error("You need to specify the application to insert a configuration!")
      }
  
      let input = {
          application_name: application_name,
          configuration_id: config_id,
          name: config_name,
          value: config_value,
          notes: config_notes,
          user_name: user_name,
          string_1: string_1
      };
      let criteria = { application_name: application_name, encrypt: encrypt };
  
      await $flows.Utilities.configurations_write({
          input: [input],
          criteria: criteria
      })
  
      $grid.refresh();
  
  } catch (error) {
      $shell.Utilities.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Ultraship_projects_configuration_dd_singleComponent),
    forwardRef(() => Ultraship_projects_dd_singleComponent),
  ],
  selector: 'Ultraship-REMOVE_ultraship_project_configurations_grid_v2',
  templateUrl: './Ultraship.REMOVE_ultraship_project_configurations_grid_v2.component.html'
})
export class Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IUltraship_REMOVE_ultraship_project_configurations_grid_v2ComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IUltraship_REMOVE_ultraship_project_configurations_grid_v2ComponentInParams = { application_name: null, user_name: null, column_names: { string_1: null, string_2: null, string_3: null }, filter_values: { string_1: null, string_2: null, string_3: null }, name: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'ID', false, false, null),       application_name: new GridHeaderModel(new HeaderStyles(null, null), 'Application', false, false, null),       user_name: new GridHeaderModel(new HeaderStyles(null, null), 'User name', false, false, null),       name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', false, false, null),       value: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       string_1: new GridHeaderModel(new HeaderStyles(null, null), 'Instance', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),       created_by: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, null),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       modified_by: new GridHeaderModel(new HeaderStyles(null, null), 'Modified by', false, false, null),       modified_on: new GridHeaderModel(new HeaderStyles(null, null), 'Modified on', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Ultraship_REMOVE_ultraship_project_configurations_grid_v2ComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('application_name') set $inParams_application_name(value: any) {
    this.inParams['application_name'] = value;
  }
  get $inParams_application_name(): any {
    return this.inParams['application_name'] ;
  }
  @Input('user_name') set $inParams_user_name(value: any) {
    this.inParams['user_name'] = value;
  }
  get $inParams_user_name(): any {
    return this.inParams['user_name'] ;
  }
  @Input('column_names') set $inParams_column_names(value: any) {
    this.inParams['column_names'] = value;
  }
  get $inParams_column_names(): any {
    return this.inParams['column_names'] ;
  }
  @Input('filter_values') set $inParams_filter_values(value: any) {
    this.inParams['filter_values'] = value;
  }
  get $inParams_filter_values(): any {
    return this.inParams['filter_values'] ;
  }
  @Input('name') set $inParams_name(value: any) {
    this.inParams['name'] = value;
  }
  get $inParams_name(): any {
    return this.inParams['name'] ;
  }

  topToolbar = {
      delete_configuration: new ToolModel(new ButtonModel('delete_configuration', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    ),
      add_configuration: new ToolModel(new ButtonModel('add_configuration', new ButtonStyles(null, null), false, 'Add', 'icon-ic_fluent_add_20_filled')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };

  formGroup: FormGroup = new FormGroup({
    encrypt: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  filters = {
    encrypt: new FieldModel(new CheckBoxModel(this.formGroup.controls['encrypt'] as DatexFormControl, null, false, 'Encrypt value')
, new ControlContainerStyles(null, null), '', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Ultraship_ShellService,
    private datasources: Ultraship_DatasourceService,
    private flows: Ultraship_FlowService,
    private reports: Ultraship_ReportService,
    private localization: Ultraship_LocalizationService,
    private operations: Ultraship_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'ultraship_project_configurations_grid_v2';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      application_name:  $grid.inParams.application_name ,
      user_name:  $grid.inParams.user_name ,
      name:  $grid.inParams.name ,
      full_text_search:  $grid.fullTextSearch ,
      string_1:  $grid.inParams.filter_values?.string_1 ,
      string_2:  $grid.inParams.filter_values?.string_2 ,
      string_3:  $grid.inParams.filter_values?.string_3 ,
    };
    try {
    const data = await this.datasources.Utilities.ds_configurations_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Ultraship_REMOVE_ultraship_project_configurations_grid_v2ComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IUltraship_REMOVE_ultraship_project_configurations_grid_v2ComponentEntity) {
    const row = new Ultraship_REMOVE_ultraship_project_configurations_grid_v2ComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations,
      this.logger);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'REMOVE_ultraship_project_configurations_grid_v2.on_init');
  
  $grid.title = 'Configurations Grid'
  $grid.headers.id.hidden = true;
  $grid.topToolbar.delete_configuration.control.readOnly = true;
  
  $grid.headers.application_name.hidden = true;
  $grid.headers.user_name.hidden = true;
  $grid.headers.name.hidden = true;
  
  if ($utils.isDefined($grid.inParams.column_names)) {
  
      let column_names = $grid.inParams.column_names;
  
      if ($utils.isDefined(column_names.string_1)) { $grid.headers.string_1.name = column_names.string_1 }
  }
  
  /** FORCE the parameter to filter specific configuration type,
   *  In this case its the string_2
   */
  
  $grid.inParams.name = $types.Ultraship.Configuration.ultraship_projects;
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'REMOVE_ultraship_project_configurations_grid_v2.on_data_loaded');
  /*
  let filter: string[] = [];
  
  filter = $grid.rows.filter(r => $utils.isDefined(r.entity.user_name))?.map(r => r.entity.configuration_id);
  if (filter.length === 0) {
      $grid.headers.user_name.hidden = true;
  } else {
      $grid.headers.user_name.hidden = false;
  }
  
  //let application_name: string = $grid.inParams.application_name ?? $settings.Ultraship.ApplicationName.toUpperCase();
  
  
  if (application_name !== "") {
      $grid.headers.application_name.hidden = true;
  } else {
      $grid.headers.application_name.hidden = false;
  }
  */
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'REMOVE_ultraship_project_configurations_grid_v2.on_delete_clicked');
  const selected_rows = $grid.selectedRows;
  let errors: { name: string, id: string, errorconfigurations: string[] }[] = [];
  let resubmitted_records = [];
  
  let logs: $types.Utilities.Log[];
  let log_payload: typeof logs[0];
  
  let configurations: $types.Utilities.Configuration[];
  let configuration_payload: typeof configurations[0];
  
  if (selected_rows.length === 0) {
      $shell.Utilities.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.Utilities.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
  
                  await $flows.Utilities.configurations_delete({
                      input: [{ configuration_id: entity.configuration_id }],
                      criteria: { application_name: entity.application_name }
                  });
  
                  await $flows.Utilities.logs_write({
                      input: [{
                          process_name: "configuration_grid",
                          level: 1,
                          notes: `Deleted setting!`,
                          message: JSON.stringify(entity)
                      }],
                      criteria: { application_name: entity.application_name }
                  });
  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { configuration: target_error }
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      }
                  }
  
                  if (!$utils.isDefined(target_error.configuration)) {
                      target_error = { configuration: `Uncaught exception! ${JSON.stringify(target_error)}` }
                  }
  
                  current_error = target_error.configuration;
              }
  
              if (success) {
                  resubmitted_records.push(row.entity.configuration_id.toString())
              } else {
                  errors.push({
                      name: row.entity.name, id: row.entity.configuration_id, errorconfigurations: [current_error]
                  });
              }
          }
  
          if ($utils.isDefined(errors)) {
  
              await $shell.Utilities.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  resubmitted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: resubmitted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorconfigurations[0], message: `Id: ${error.id} Name: ${error.name}` })))
          }
      }
  }
  
  $grid.refresh();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'REMOVE_ultraship_project_configurations_grid_v2.on_row_selected');
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_configuration.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'REMOVE_ultraship_project_configurations_grid_v2.on_export_flow');
  
  if ($grid.rows.length > 0) {
  
      let ids: string[] = [];
  
      if ($utils.isDefined($grid.selectedRows)) {
          if ($grid.selectedRows.length > 0) {
              ids = $grid.selectedRows.map(o => o.entity.configuration_id);
          }
      }
  
      let full_text_search = $grid.fullTextSearch;
      let application_name = $grid.inParams.application_name;
  
      let data = (await $datasources.Utilities.ds_configurations_grid.getList({
          full_text_search: full_text_search,
          application_name: application_name
      }));
  
      if (data.totalCount > 0) {
  
          let config: any;
          let configs: any[] = [];
  
          let results = data.result;
  
          if (ids.length > 0) {
              results = results.filter(r => ids.indexOf(r.configuration_id) >= 0);
          }
  
          for (let result of results) {
              config = {
                  "Application": result.application_name,
                  "User Name": result.user_name,
                  "Name": result.name,
                  "Value": result.value.substring(0, 30000),
                  "Notes": result.notes.substring(0, 30000),
                  "Created By": result.created_by,
                  "Created On": result.created_on,
                  "Modified By": result.modified_by,
                  "Modified On": result.modified_on
              }
  
              if ($utils.isDefined(result.string_1)) { let name = "String 1"; if ($utils.isDefined($grid.inParams.column_names?.string_1)) { name = $grid.inParams.column_names?.string_1 } config[name] = result.string_1 }
  
              config.push(config);
          }
  
          let wb = $event;
          let ws = $utils.excel.json_to_sheet(configs);
          $utils.excel.book_append_sheet(wb, ws, "Configurations");
  
      } else {
          $shell.Utilities.openInfoDialog('Error', 'The parameters did not return any records to export.')
      }
  } else {
      $shell.Utilities.openInfoDialog('Error', 'There are no records to export!')
  }
  }
  on_add_clicked(event = null) {
    return this.on_add_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_clickedInternal(
    $grid: Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component,
  
    $shell: Ultraship_ShellService,
    $datasources: Ultraship_DatasourceService,
    $flows: Ultraship_FlowService,
    $reports: Ultraship_ReportService,
    $settings: SettingsValuesService,
    $operations: Ultraship_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Ultraship_LocalizationService,
    $event: any
  ) {
    this.logger.log('Ultraship', 'REMOVE_ultraship_project_configurations_grid_v2.on_add_clicked');
  try {
      const projectConfig = (await $shell.Ultraship.openproject_configuration_add_formDialog());
  
      if (!$utils.isDefined(projectConfig)) {
          // do nothing
      }
  
  } catch (error) {
  
      let target_error = error;
  
      if (typeof target_error === "string") {
          target_error = { message: target_error };
      } else {
          while ($utils.isDefined(target_error.error)) {
              target_error = target_error.error;
          };
      };
  
      if (!$utils.isDefined(target_error.message)) {
          target_error = { message: "Uncaught exception!" };
      };
  
      const errorDescription = `${target_error.message}`;
      await $shell.Ultraship.openErrorDialog('New project configuration error', 'An error occurred during creation of a new project configuration', [errorDescription], null, null);
  };
  
  $grid.refresh();
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'Ultraship_REMOVE_ultraship_project_configurations_grid_v2Component_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
