import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService, EModalSize, EToasterType, EToasterPosition } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Invoices.frontend.types'
import { $frontendTypes as $types} from './Invoices.frontend.types' 


import { Invoices_freight_billing_header_gridComponent } from './Invoices.freight_billing_header_grid.component';
import { Invoices_freight_billing_warehouse_selector_singleComponent } from './Invoices.freight_billing_warehouse_selector_single.component'
import { Invoices_freight_billing_owner_selector_singleComponent } from './Invoices.freight_billing_owner_selector_single.component'
import { Invoices_freight_billing_projects_selector_singleComponent } from './Invoices.freight_billing_projects_selector_single.component'
import { Invoices_freight_billing_account_selector_singleComponent } from './Invoices.freight_billing_account_selector_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Invoices_freight_billing_header_gridComponent),
    forwardRef(() => Invoices_freight_billing_warehouse_selector_singleComponent),
    forwardRef(() => Invoices_freight_billing_owner_selector_singleComponent),
    forwardRef(() => Invoices_freight_billing_projects_selector_singleComponent),
    forwardRef(() => Invoices_freight_billing_account_selector_singleComponent),
  ],
  selector: 'Invoices-freight_billing_hub',
  templateUrl: './Invoices.freight_billing_hub.component.html'
})
export class Invoices_freight_billing_hubComponent extends BaseComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    warehouse_filter: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    owner_filter: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project_filter: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    account_filter: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      new_matrix: new ToolModel(new ButtonModel('new_matrix', new ButtonStyles(['primary'], null), false, 'New matrix', 'ms-Icon ms-Icon--Add')
    )
  };

  actionbar = {
  };

 filters = {
    warehouse_filter: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['warehouse_filter'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Warehouse', false)
,
    owner_filter: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner_filter'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', false)
,
    project_filter: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project_filter'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    account_filter: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['account_filter'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Account', false)
,
  };


  filtersets = {
  filters_group: new FieldsetModel('Filters', false, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      freight_matrix: new TabItemModel(
        this.rootTabGroup, 
        'Freight matrix', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_freight_matrix_freight_billing_header_grid_inParams_warehouse_id(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.warehouse_filter.control.value;
      
      return expr;
    }
  
    get $tabs_freight_matrix_freight_billing_header_grid_inParams_owner_id(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.owner_filter.control.value;
      
      return expr;
    }
  
    get $tabs_freight_matrix_freight_billing_header_grid_inParams_account_id(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.account_filter.control.value;
      
      return expr;
    }
  
    get $tabs_freight_matrix_freight_billing_header_grid_inParams_project_id(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project_filter.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_freight_matrix', { read: Invoices_freight_billing_header_gridComponent }) $tabs_freight_matrix: Invoices_freight_billing_header_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_project_filter_selector_inParams_owner_id(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner_filter.control.value;
    
    return expr;
  }



  get $fields_account_filter_selector_inParams_owner_id(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner_filter.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Freight billing`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Manage freight charges across projects and accounts`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: Invoices_ShellService,
  private datasources: Invoices_DatasourceService,
  private flows: Invoices_FlowService,
  private reports: Invoices_ReportService,
  private localization: Invoices_LocalizationService,
  private operations: Invoices_OperationService,
  private logger: CleanupLoggerService,
  ) { 
    super();
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.freight_matrix,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Freight Billing';
    
    const $hub = this;
    const $utils = this.utils;



    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_freight_matrix') {
      if (!isNil(this.$tabs_freight_matrix) && !this.tabs.freight_matrix.hidden) {
        this.$tabs_freight_matrix.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_new_matrix_clicked(event = null) {
    return this.on_new_matrix_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_matrix_clickedInternal(
    $hub: Invoices_freight_billing_hubComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  
  await $flows.Invoices.freight_billing_header_storage({
      payload: [{
          lookup_code: " ",
      }],
      action: "Write"
  })
  
  let result = (await $flows.Invoices.freight_billing_header_storage({
      payload: [{
          lookup_code: " "
      }],
      action: `Read`
  }));
  
  if ($utils.isDefined(result)) {
  
      let freight_header_id = result.payload[0].header_id
      const dialogResults = (await $shell.Invoices.openfreight_billing_matrix_editorDialog({
          freight_header_id: freight_header_id,
  
      }, 'flyout', EModalSize.Large));
  
      if ($utils.isDefined(dialogResults)) {
  
          const confirm = dialogResults.confirm;
          if ($utils.isDefined(confirm)) {
  
              if (confirm) {
                  $hub.refresh();
              }
          }
          const on_close = dialogResults.on_close;
          if ($utils.isDefined(on_close)) {
  
              if (on_close) {
                  $hub.refresh();
              }
          }
      }
  }
  }
  //#endregion private flows
}
