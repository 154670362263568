import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { CleanupLoggerService } from './cleanup.logging.service';
import { app_custom_print_order_labels_formComponent } from './app.custom_print_order_labels_form.component';
import { app_footprint_wms_homeComponent } from './app.footprint_wms_home.component';
import { app_custom_invoice_editorComponent } from './app.custom_invoice_editor.component';
import { app_custom_excel_order_import_gridComponent } from './app.custom_excel_order_import_grid.component';
import { app_custom_invoice_gridComponent } from './app.custom_invoice_grid.component';
import { app_custom_materials_udf_gridComponent } from './app.custom_materials_udf_grid.component';
import { app_custom_purchase_order_lines_gridComponent } from './app.custom_purchase_order_lines_grid.component';
import { app_original_excel_order_import_gridComponent } from './app.original_excel_order_import_grid.component';
import { app_custom_carriers_dd_singleComponent } from './app.custom_carriers_dd_single.component';
import { app_custom_order_class_dd_singleComponent } from './app.custom_order_class_dd_single.component';
import { app_custom_projects_dd_singleComponent } from './app.custom_projects_dd_single.component';
import { app_custom_udf_allergen_dd_singleComponent } from './app.custom_udf_allergen_dd_single.component';
import { app_custom_carriers_dd_multiComponent } from './app.custom_carriers_dd_multi.component';
import { app_custom_order_class_dd_multiComponent } from './app.custom_order_class_dd_multi.component';
import { app_custom_projects_dd_multiComponent } from './app.custom_projects_dd_multi.component';
import { app_custom_udf_allergen_dd_multiComponent } from './app.custom_udf_allergen_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';
import { PrintNode_ShellService } from './PrintNode.shell.service';
import { Materials_ShellService } from './Materials.shell.service';
import { PalletTransactions_ShellService } from './PalletTransactions.shell.service';
import { PurchaseOrders_ShellService } from './PurchaseOrders.shell.service';
import { Lots_ShellService } from './Lots.shell.service';
import { Owners_ShellService } from './Owners.shell.service';
import { Discussions_ShellService } from './Discussions.shell.service';
import { ExcelOrderImport_ShellService } from './ExcelOrderImport.shell.service';
import { Document360_ShellService } from './Document360.shell.service';
import { Usersnap_ShellService } from './Usersnap.shell.service';
import { Locations_ShellService } from './Locations.shell.service';
import { Inventory_ShellService } from './Inventory.shell.service';
import { Ultraship_ShellService } from './Ultraship.shell.service';
import { ExcelInventoryImport_ShellService } from './ExcelInventoryImport.shell.service';
import { Dynamics365BusinessCentral_ShellService } from './Dynamics365BusinessCentral.shell.service';
import { Notifications_ShellService } from './Notifications.shell.service';
import { Invoices_ShellService } from './Invoices.shell.service';
import { SalesOrders_ShellService } from './SalesOrders.shell.service';
import { FootPrintManager_ShellService } from './FootPrintManager.shell.service';
import { FootPrintApiManager_ShellService } from './FootPrintApiManager.shell.service';
import { Reports_ShellService } from './Reports.shell.service';

@Injectable({ providedIn: 'root' })
export class app_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    private logger: CleanupLoggerService,
    public Utilities: Utilities_ShellService,
    public PrintNode: PrintNode_ShellService,
    public Materials: Materials_ShellService,
    public PalletTransactions: PalletTransactions_ShellService,
    public PurchaseOrders: PurchaseOrders_ShellService,
    public Lots: Lots_ShellService,
    public Owners: Owners_ShellService,
    public Discussions: Discussions_ShellService,
    public ExcelOrderImport: ExcelOrderImport_ShellService,
    public Document360: Document360_ShellService,
    public Usersnap: Usersnap_ShellService,
    public Locations: Locations_ShellService,
    public Inventory: Inventory_ShellService,
    public Ultraship: Ultraship_ShellService,
    public ExcelInventoryImport: ExcelInventoryImport_ShellService,
    public Dynamics365BusinessCentral: Dynamics365BusinessCentral_ShellService,
    public Notifications: Notifications_ShellService,
    public Invoices: Invoices_ShellService,
    public SalesOrders: SalesOrders_ShellService,
    public FootPrintManager: FootPrintManager_ShellService,
    public FootPrintApiManager: FootPrintApiManager_ShellService,
    public Reports: Reports_ShellService,
  ) {
    super(dialog, toastr);
  }

  public app: app_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public opencustom_print_order_labels_form(inParams:{ orderId: number, context?: string, shipmentId?: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_print_order_labels_form');
    ShellService.openViewRequest$.next(
      {
        title: 'Custom print order labels form',
        referenceName: 'custom_print_order_labels_form',
        component: app_custom_print_order_labels_formComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_print_order_labels_formDialog(
    inParams:{ orderId: number, context?: string, shipmentId?: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_print_order_labels_form');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_print_order_labels_formComponent,
      'Custom print order labels form',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openfootprint_wms_home(replaceCurrentView?: boolean) {
    this.logger.log('app', 'footprint_wms_home');
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'footprint_wms_home',
        component: app_footprint_wms_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openfootprint_wms_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'footprint_wms_home');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_footprint_wms_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public opencustom_invoice_editor(inParams:{ invoiceId: number, invoice_lines?: { open_to_line?: number } }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_invoice_editor');
    ShellService.openViewRequest$.next(
      {
        title: 'Edit Invoice',
        referenceName: 'custom_invoice_editor',
        component: app_custom_invoice_editorComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_invoice_editorDialog(
    inParams:{ invoiceId: number, invoice_lines?: { open_to_line?: number } }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_invoice_editor');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_invoice_editorComponent,
      'Edit Invoice',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_excel_order_import_grid(replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_excel_order_import_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Custom Order Import',
        referenceName: 'custom_excel_order_import_grid',
        component: app_custom_excel_order_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public opencustom_excel_order_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_excel_order_import_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_excel_order_import_gridComponent,
      'Custom Order Import',
      mode,
      dialogSize
    )
  }
  public opencustom_invoice_grid(inParams:{ ownerId?: number, projectId?: number, statusIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_invoice_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Invoices',
        referenceName: 'custom_invoice_grid',
        component: app_custom_invoice_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_invoice_gridDialog(
    inParams:{ ownerId?: number, projectId?: number, statusIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_invoice_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_invoice_gridComponent,
      'Invoices',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_materials_udf_grid(inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_materials_udf_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Materials Advanced',
        referenceName: 'custom_materials_udf_grid',
        component: app_custom_materials_udf_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_materials_udf_gridDialog(
    inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_materials_udf_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_materials_udf_gridComponent,
      'Materials Advanced',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public opencustom_purchase_order_lines_grid(inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, replaceCurrentView?: boolean) {
    this.logger.log('app', 'custom_purchase_order_lines_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Order Lines',
        referenceName: 'custom_purchase_order_lines_grid',
        component: app_custom_purchase_order_lines_gridComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public opencustom_purchase_order_lines_gridDialog(
    inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'custom_purchase_order_lines_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_custom_purchase_order_lines_gridComponent,
      'Order Lines',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openoriginal_excel_order_import_grid(replaceCurrentView?: boolean) {
    this.logger.log('app', 'original_excel_order_import_grid');
    ShellService.openViewRequest$.next(
      {
        title: 'Order Import',
        referenceName: 'original_excel_order_import_grid',
        component: app_original_excel_order_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openoriginal_excel_order_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    this.logger.log('app', 'original_excel_order_import_grid');
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      app_original_excel_order_import_gridComponent,
      'Order Import',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'custom_print_order_labels_form') {
      this.logger.log('app', 'custom_print_order_labels_form');
      const title = 'Custom print order labels form';
      const component = app_custom_print_order_labels_formComponent;
      const inParams:{ orderId: number, context?: string, shipmentId?: number } = { orderId: null, context: null, shipmentId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('context'))) {
        const paramValueString = params.get('context');
        // TODO: date
        inParams.context = paramValueString;
              }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'footprint_wms_home') {
      this.logger.log('app', 'footprint_wms_home');
      const title = 'Home';
      const component = app_footprint_wms_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_invoice_editor') {
      this.logger.log('app', 'custom_invoice_editor');
      const title = 'Edit Invoice';
      const component = app_custom_invoice_editorComponent;
      const inParams:{ invoiceId: number, invoice_lines?: { open_to_line?: number } } = { invoiceId: null, invoice_lines: { open_to_line: null } };
      if (!isNil(params.get('invoiceId'))) {
        const paramValueString = params.get('invoiceId');
        inParams.invoiceId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('invoice_lines'))) {
        const paramValueString = params.get('invoice_lines');
        inParams.invoice_lines = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_excel_order_import_grid') {
      this.logger.log('app', 'custom_excel_order_import_grid');
      const title = 'Custom Order Import';
      const component = app_custom_excel_order_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_invoice_grid') {
      this.logger.log('app', 'custom_invoice_grid');
      const title = 'Invoices';
      const component = app_custom_invoice_gridComponent;
      const inParams:{ ownerId?: number, projectId?: number, statusIds?: number[] } = { ownerId: null, projectId: null, statusIds: [] };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_materials_udf_grid') {
      this.logger.log('app', 'custom_materials_udf_grid');
      const title = 'Materials Advanced';
      const component = app_custom_materials_udf_gridComponent;
      const inParams:{ projectId?: number, ownerId?: number, materialIds?: number[] } = { projectId: null, ownerId: null, materialIds: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('materialIds'))) {
        const paramValueString = params.get('materialIds');
        inParams.materialIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_purchase_order_lines_grid') {
      this.logger.log('app', 'custom_purchase_order_lines_grid');
      const title = 'Order Lines';
      const component = app_custom_purchase_order_lines_gridComponent;
      const inParams:{ orderId: number, shipmentId: number, projectId: number, orderStatusId: number } = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null };
      if (!isNil(params.get('orderId'))) {
        const paramValueString = params.get('orderId');
        inParams.orderId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('orderStatusId'))) {
        const paramValueString = params.get('orderStatusId');
        inParams.orderStatusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'original_excel_order_import_grid') {
      this.logger.log('app', 'original_excel_order_import_grid');
      const title = 'Order Import';
      const component = app_original_excel_order_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_carriers_dd_single') {
      const title = 'custom_carriers_dd';
      const component = app_custom_carriers_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_order_class_dd_single') {
      const title = 'custom_order_class_dd';
      const component = app_custom_order_class_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_projects_dd_single') {
      const title = 'Projects dropdown';
      const component = app_custom_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_udf_allergen_dd_single') {
      const title = 'custom_udf_allergen_dd';
      const component = app_custom_udf_allergen_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_carriers_dd_multi') {
      const title = 'custom_carriers_dd';
      const component = app_custom_carriers_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_order_class_dd_multi') {
      const title = 'custom_order_class_dd';
      const component = app_custom_order_class_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_projects_dd_multi') {
      const title = 'Projects dropdown';
      const component = app_custom_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'custom_udf_allergen_dd_multi') {
      const title = 'custom_udf_allergen_dd';
      const component = app_custom_udf_allergen_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PrintNode.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Materials.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PalletTransactions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.PurchaseOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Lots.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Owners.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Discussions.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelOrderImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Document360.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Usersnap.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Locations.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Inventory.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Ultraship.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.ExcelInventoryImport.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Dynamics365BusinessCentral.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Notifications.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Invoices.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.SalesOrders.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.FootPrintApiManager.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    result = this.Reports.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
