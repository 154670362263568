import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { BaseComponent } from './components/base.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService, EModalSize, EToasterType, EToasterPosition } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 

import { Utilities_statuses_dd_singleComponent } from './Utilities.statuses_dd_single.component'


interface IDynamics365BusinessCentral_messages_success_gridComponentEntity {
message_id?: string, application_name?: string, process_name?: string, transaction_type?: string, transaction_id?: string, status_name?: string, environment?: string, direction?: string, reference?: string, payload?: string, notes?: string, message?: string, created_by?: string, created_on?: string, modified_by?: string, modified_on?: string, number_1?: number, number_2?: number, number_3?: number, number_4?: number, number_5?: number, string_1?: string, string_2?: string, string_3?: string, string_4?: string, string_5?: string, date_number?: number}

interface IDynamics365BusinessCentral_messages_success_gridComponentInParams {
  transaction_id?: string, application_name?: string, customerr?: string}

interface IDynamics365BusinessCentral_messages_success_gridComponentOutParams {
  InvoiceId?: number}

class Dynamics365BusinessCentral_messages_success_gridComponentRowModel extends GridRowModel {
  grid: Dynamics365BusinessCentral_messages_success_gridComponent;
  entity: IDynamics365BusinessCentral_messages_success_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookup: new GridCellModel(
      new CellStyles(['grid-table-cell-link','left'], null),
      new TextModel(null, null )
,
null
      ),
    bc_status: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null )
,
null
      ),
    bc_lookup: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null )
,
null
      ),
    modified_on: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null , 'datetime', 'l, LT')
,
null
      ),
    modified_by: new GridCellModel(
      new CellStyles(['left'], null),
      new TextModel(null, null )
,
null
      ),
    payload: new GridCellModel(
      new CellStyles(['grid-table-cell-link','left'], null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Dynamics365BusinessCentral_ShellService,
private datasources: Dynamics365BusinessCentral_DatasourceService,
private flows: Dynamics365BusinessCentral_FlowService,
private reports: Dynamics365BusinessCentral_ReportService,
private localization: Dynamics365BusinessCentral_LocalizationService,
private operations: Dynamics365BusinessCentral_OperationService,
private logger: CleanupLoggerService,
) {
    super();
    
  }

  async $initializeExisting(grid: Dynamics365BusinessCentral_messages_success_gridComponent, entity: IDynamics365BusinessCentral_messages_success_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.message_id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Dynamics365BusinessCentral_messages_success_gridComponent, entity?: IDynamics365BusinessCentral_messages_success_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.message_id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.message_id;
    const inParams = {
      $keys:[$resultKey],
      application_name:  $grid.inParams.application_name ,
      date_from:  null ,
      date_to:  null ,
      full_text_search:  $grid.fullTextSearch ,
      process_name:  $grid.inParams.customerr ,
      transaction_id:  $grid.inParams.transaction_id ,
      status_name:  $grid.filters.status.control.value ,
      reference:  null ,
      transaction_type:  null ,
      direction:  null ,
      environment:  null ,
      string_1:  null ,
      string_2:  $grid.filters.type.control.value ,
      string_3:  null ,
      string_4:  null ,
      string_5:  null ,
      number_1:  null ,
      number_2:  null ,
      number_3:  null ,
      number_4:  null ,
      number_5:  null ,
    };
    const data = await this.datasources.Utilities.ds_messages_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookup.displayControl as TextModel).text = $row.entity.string_1;
    (this.cells.bc_status.displayControl as TextModel).text = $row.entity.string_3;
    (this.cells.bc_lookup.displayControl as TextModel).text = $row.entity.string_5;
    (this.cells.modified_on.displayControl as TextModel).text = $row.entity.modified_on?.toString();
    (this.cells.modified_by.displayControl as TextModel).text = $row.entity.modified_by;
    (this.cells.payload.displayControl as TextModel).text = $row.entity.notes;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookup' : {
        this.on_lookup_clicked();
        break;
      }
      case 'payload' : {
        this.on_payload_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Dynamics365BusinessCentral_messages_success_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_messages_success_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  const format = `MM/DD/YYYY, LT`;
  
  
  let payload = $row.entity.payload;
  
  if ($utils.isDefined(payload)) {
      payload = (payload.length > 75) ? payload.substring(0, 75) : payload;
      $row.cells.payload.displayControl.text = payload;
  };
  
  if ($row.entity.string_3=='Draft') {
      $row.cells.bc_status.styles.setPlannedClass()
  } else 
  {if ($row.entity.string_3=='Open')  
  {$row.cells.bc_status.styles.setCreationClass() 
  }
  else 
  {if ($row.entity.string_3=='Paid') {
      $row.cells.bc_status.styles.setStyle('color','rgba(0, 0, 194, 1)'); 
  } else 
  {$row.cells.bc_status.styles.setStyle('color','rgba(233, 22, 22, 0.78)'); 
  }
  }
  }
  
  }
  on_payload_clicked(event = null) {
    return this.on_payload_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_payload_clickedInternal(
    $row: Dynamics365BusinessCentral_messages_success_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_messages_success_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  let payload_json: any;
  let payload_xml: any;
  let payload_string: string = $row.entity.payload;
  let lookup = $row.entity.string_1
  try {
      payload_json = JSON.parse(payload_string);
  } catch (e) {
  };
  
  if (!$utils.isDefined(payload_json)) {
      try {
          payload_string = payload_string.trim();
          if (payload_string.startsWith("<") && payload_string.endsWith(">")) {
              payload_json = $utils.parseXml(payload_string)
              if ($utils.isDefined(payload_json)) {
                  payload_xml = payload_string;
                  payload_json = null;
              };
          };
      } catch (e) {
      };
  };
  
  if (!$utils.isDefined(payload_json) && !$utils.isDefined(payload_xml)) {
      $shell.Utilities.openInfoDialog("Payload", payload_string);
  };
  if ($utils.isDefined(payload_json)) {
      await $shell.Utilities.openview_json_payloadDialog({ payload: payload_json, title: `Payload for invoice `+lookup }, 'flyout', EModalSize.Large);
  };
  if ($utils.isDefined(payload_xml)) {
      await $shell.Utilities.openview_xml_payloadDialog({ payload: payload_xml, title: `Payload for invoice `+lookup }, 'flyout', EModalSize.Large);;
  };
  }
  on_notes_clicked(event = null) {
    return this.on_notes_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_notes_clickedInternal(
    $row: Dynamics365BusinessCentral_messages_success_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_messages_success_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'messages_success_grid.on_notes_clicked');
  
  let payload_json: any;
  let payload_xml: any;
  let payload_string: string = $row.entity.notes;
  
  try {
      payload_json = JSON.parse(payload_string);
  } catch (e) {
  };
  
  if (!$utils.isDefined(payload_json)) {
      try {
          payload_string = payload_string.trim();
          if (payload_string.startsWith("<") && payload_string.endsWith(">")) {
              payload_json = $utils.parseXml(payload_string)
              if ($utils.isDefined(payload_json)) {
                  payload_xml = payload_string;
                  payload_json = null;
              };
          };
      } catch (e) {
      };
  };
  
  if (!$utils.isDefined(payload_json) && !$utils.isDefined(payload_xml)) {
      $shell.Utilities.openInfoDialog("Notes", payload_string);
  };
  if ($utils.isDefined(payload_json)) {
      await $shell.Utilities.openview_json_payloadDialog({ payload: payload_json, title: "Notes" });
  };
  if ($utils.isDefined(payload_xml)) {
      await $shell.Utilities.openview_xml_payloadDialog({ payload: payload_xml, title: "Notes" });
  };
  }
  on_transaction_clicked(event = null) {
    return this.on_transaction_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_transaction_clickedInternal(
    $row: Dynamics365BusinessCentral_messages_success_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_messages_success_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'messages_success_grid.on_transaction_clicked');
  let transaction_id: string = $row.entity.transaction_id;
  
  if ($utils.isDefined(transaction_id)) {
      if (transaction_id !== "") {
          $shell.Utilities.opentransaction_hubDialog({transaction_id: transaction_id});
      };
  };
  }
  on_comments_clicked(event = null) {
    return this.on_comments_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_comments_clickedInternal(
    $row: Dynamics365BusinessCentral_messages_success_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_messages_success_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'messages_success_grid.on_comments_clicked');
  
  }
  on_lookup_clicked(event = null) {
    return this.on_lookup_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookup_clickedInternal(
    $row: Dynamics365BusinessCentral_messages_success_gridComponentRowModel,
  $grid: Dynamics365BusinessCentral_messages_success_gridComponent, 
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  
  
  
  let application_name = $settings.Dynamics365BusinessCentral.application
  let customer = $settings.Dynamics365BusinessCentral.customer
  
  let GroupLines = (await $flows.Utilities.configurations_get_one({
          input: [{ name: 'GroupLines', string_1:  customer}],
          criteria: { application_name: application_name, decrypt: false },
      }))?.output
  
  let InvoiceId:number=Number($row.entity.number_1)
  
  if(GroupLines.value ==='yes')
  {$shell.Dynamics365BusinessCentral.openinvoice_line_grouped_gridDialog({InvoiceId:InvoiceId}, 'flyout', EModalSize.Xlarge)}
  else
  {$shell.Dynamics365BusinessCentral.openinvoice_line_gridDialog({InvoiceId:InvoiceId}, 'flyout', EModalSize.Xlarge)}
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Utilities_statuses_dd_singleComponent),
  ],
  selector: 'Dynamics365BusinessCentral-messages_success_grid',
  templateUrl: './Dynamics365BusinessCentral.messages_success_grid.component.html'
})
export class Dynamics365BusinessCentral_messages_success_gridComponent extends BaseComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IDynamics365BusinessCentral_messages_success_gridComponentEntity[];

  pageSize = 20;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IDynamics365BusinessCentral_messages_success_gridComponentInParams = { transaction_id: null, application_name: null, customerr: null };

  outParams: IDynamics365BusinessCentral_messages_success_gridComponentOutParams = { InvoiceId: null };

  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ InvoiceId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  headers = {
     lookup: new GridHeaderModel(new HeaderStyles(['left'], null), 'Invoice', false, false, null),       bc_status: new GridHeaderModel(new HeaderStyles(['left'], null), 'Billing status', false, false, null),       bc_lookup: new GridHeaderModel(new HeaderStyles(['left'], null), 'Business Central reference', false, false, null),       modified_on: new GridHeaderModel(new HeaderStyles(['left'], null), 'Posted on', false, false, null),       modified_by: new GridHeaderModel(new HeaderStyles(['left'], null), 'Posted by', false, false, null),       payload: new GridHeaderModel(new HeaderStyles(['left'], null), 'Payload', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Dynamics365BusinessCentral_messages_success_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('transaction_id') set $inParams_transaction_id(value: any) {
    this.inParams['transaction_id'] = value;
  }
  get $inParams_transaction_id(): any {
    return this.inParams['transaction_id'] ;
  }
  @Input('application_name') set $inParams_application_name(value: any) {
    this.inParams['application_name'] = value;
  }
  get $inParams_application_name(): any {
    return this.inParams['application_name'] ;
  }
  @Input('customerr') set $inParams_customerr(value: any) {
    this.inParams['customerr'] = value;
  }
  get $inParams_customerr(): any {
    return this.inParams['customerr'] ;
  }

  topToolbar = {
      re0penbutton: new ToolModel(new ButtonModel('re0penbutton', new ButtonStyles(null, null), false, 'Reverse to pending', 'icon-ic_fluent_arrow_undo_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      delete_message: new ToolModel(new ButtonModel('delete_message', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['status'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Status', false)
,
    type: new FieldModel(new TextBoxModel(this.formGroup.controls['type'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'type', false)
,
  }

  //#region filters inParams
  get $fields_status_selector_inParams_application_name(): string {
    const $grid = this;
    const $utils = this.utils;
    const expr = 'Dynamics365BusinessCentral';
    
    return expr;
  }

  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Dynamics365BusinessCentral_ShellService,
    private datasources: Dynamics365BusinessCentral_DatasourceService,
    private flows: Dynamics365BusinessCentral_FlowService,
    private reports: Dynamics365BusinessCentral_ReportService,
    private localization: Dynamics365BusinessCentral_LocalizationService,
    private operations: Dynamics365BusinessCentral_OperationService,
    private logger: CleanupLoggerService,
    ) {
    super();
    this.title = 'Post success';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();

    this.clearRows();
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.status.control as SelectBoxModel).reset('Ok');
    (this.filters.type.control as TextBoxModel).reset('header');

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  protected override $initEmpty() {
    this.clearRows();
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      application_name:  $grid.inParams.application_name ,
      date_from:  null ,
      date_to:  null ,
      full_text_search:  $grid.fullTextSearch ,
      process_name:  $grid.inParams.customerr ,
      transaction_id:  $grid.inParams.transaction_id ,
      status_name:  $grid.filters.status.control.value ,
      reference:  null ,
      transaction_type:  null ,
      direction:  null ,
      environment:  null ,
      string_1:  null ,
      string_2:  $grid.filters.type.control.value ,
      string_3:  null ,
      string_4:  null ,
      string_5:  null ,
      number_1:  null ,
      number_2:  null ,
      number_3:  null ,
      number_4:  null ,
      number_5:  null ,
    };
    try {
    const data = await this.datasources.Utilities.ds_messages_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    this.clearRows();

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Dynamics365BusinessCentral_messages_success_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations,
          this.logger);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  clearRows() {
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Dynamics365BusinessCentral_messages_success_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  const selectedRowsCount = $grid.selectedRows.length;
  $grid.topToolbar.delete_message.control.readOnly = (selectedRowsCount > 0 ? false : true);
  $grid.topToolbar.re0penbutton.control.readOnly = (selectedRowsCount > 0 ? false : true);
  
  async function check_status(allow: boolean) {
      for (let row of $grid.selectedRows) {
          if (row.entity.status_name !== "Ready")
          {
              allow = false;
          };
      };
      return allow;
  };
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Dynamics365BusinessCentral_messages_success_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  
  $grid.topToolbar.delete_message.hidden = true;
  $grid.topToolbar.re0penbutton.hidden = true;
  $grid.filters.status.hidden=true
  $grid.filters.type.hidden=true
  
  // if (await $operations.FootPrintApiManager.Enable_ApiManager_ConfigurationsGrid.isAuthorized()) {
  //     $grid.topToolbar.delete_message.hidden = false;
  //     $grid.topToolbar.re0penbutton.control.readOnly = false;
  // };
  
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: Dynamics365BusinessCentral_messages_success_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  let application_name = $settings.Dynamics365BusinessCentral.application
  const selectedRowsCount = $grid.selectedRows.length;
  const rows = $grid.rows
  for (const row of rows) {
      let entity = row.entity
      let status = entity.string_3
      let inv_id = entity.string_4
      let m_id =entity.message_id
       let Id= entity.number_1
      let istatus= entity.number_2
      let type = entity.string_2
   if ((status == 'Draft' || status == 'Open')  && inv_id !='' && type=='header') {
  
  await $flows.Dynamics365BusinessCentral.flow_message_status_update({m_id:m_id,inv_id:inv_id})
  $grid.refresh
      }
  else
  {
  if (status == 'Paid' && istatus == 2)
  { const invoices_payload1: any = {};
      invoices_payload1.statusId = 3
      $flows.Utilities.crud_update_flow({
          entitySet: 'Invoices',
          id: Id,
          entity: invoices_payload1
      })
      
     await $flows.Utilities.messages_update({
      input: [{ message_id:m_id,number_2:3}],
      criteria: { application_name: application_name }
                   })              
      
      }
  
  }
  
  $grid.refresh    
  }
  $grid.refresh
  
  
  
  
  
  
  
  
  
  
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Dynamics365BusinessCentral_messages_success_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  let application_name = $settings.Dynamics365BusinessCentral.application
   const selected_rows = $grid.selectedRows;
  
  let errors: { reference: string, id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  
  
  if (selected_rows.length === 0) {
      $shell.Utilities.openErrorDialog('Revert error!', 'No records were selected!');
      return;
  } else {
  
        const confirm_post = (await $shell.Utilities.openConfirmationDialog(`Confirm Delete!`, `Do you want to delete invoice and invoice lines from FP and BC?`, `Post`, `Cancel`));
  
      if (confirm_post) {
    
          for (const row of selected_rows) {
                  let entity = row.entity
                  let m_id = entity.message_id
                  let InvoiceId:number= Number(row.entity.number_1)
                  let inv_id =entity.string_4
              try {
                         
                                            
              let i=   (await $datasources.Dynamics365BusinessCentral.ds_get_invoice_lines.get({InvoiceId:InvoiceId})).result
              let b = i?.map(I => I.Id)
              b.forEach(async (Id)=> { 
  
              $flows.Utilities.crud_delete_flow({
              entitySet: 'InvoiceLines',
              id: Id  })
              })
  
              $flows.Utilities.crud_delete_flow({
              entitySet:  'Invoices',
              id: InvoiceId }),
  
              await $flows.Dynamics365BusinessCentral.flow_delete_invoice({inv_id:inv_id})   
  
              await $flows.Utilities.messages_delete({
              input: [{ message_id:m_id}],
              criteria: { application_name: application_name }
               })  
  
                  }catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
               let   current_error = target_error.message;
               $shell.Utilities.openErrorDialog('Revert error!', current_error);
              return;
              };
              };
  
              };
  
  };
  
   $grid.refresh();
  }
  on_reopen_clicked(event = null) {
    return this.on_reopen_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_reopen_clickedInternal(
    $grid: Dynamics365BusinessCentral_messages_success_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  let application_name = $settings.Dynamics365BusinessCentral.application
   const selected_rows = $grid.selectedRows;
  
  let errors: { reference: string, id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  
  
  if (selected_rows.length === 0) {
      $shell.Utilities.openErrorDialog('Revert error!', 'No records were selected!');
      return;
  } else {
  
    
          for (const row of selected_rows) {
                  let entity = row.entity
                  let lookup:string= row.entity.string_1
                  let InvoiceId:number= Number(row.entity.number_1)
                  let date = $utils.date.now();
                  let user = (await $flows.Utilities.get_username_flow({})).userName
                  let m_id = entity.message_id
                  let inv_id =entity.string_4
                  let status =entity.string_3
  
              if (status !='Draft'){
                $shell.Utilities.openErrorDialog('Reverse to pending is not allowed!', 'Invoice is already opened and processed in Business Central :(');
               return;
              } else {
             
             
              try {
  
                   await $flows.Utilities.messages_delete({
                   input: [{ message_id:m_id}],
                    criteria: { application_name: application_name }
                   })            
  
                  const invoices_payload: any = {};
                  invoices_payload.StatusId = 1
                  await $flows.Utilities.crud_update_flow({
                  entitySet: 'Invoices',
                  id: InvoiceId,
                   entity: invoices_payload
                  })
  
                 await $flows.Dynamics365BusinessCentral.flow_delete_invoice({inv_id:inv_id})            
  
                  invoices_payload.Notes = `Invoice re-opened by ${user} on ${date}`
                  $flows.Utilities.crud_update_flow({
                  entitySet: 'Invoices',
                  id: InvoiceId,
                  entity: invoices_payload
  
                  })
                  
                  
  
               }catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
               let   current_error = target_error.message;
                $shell.Utilities.openErrorDialog('Revert error!',  current_error );
               return;
              };
              };
  
              };
  };
  
  
   $grid.refresh();
  }
  on_check(event = null) {
    return this.on_checkInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_checkInternal(
    $grid: Dynamics365BusinessCentral_messages_success_gridComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
    this.logger.log('Dynamics365BusinessCentral', 'messages_success_grid.on_check');
  const rows = $grid.rows
  for (const row of rows) {
      let entity = row.entity
      let status = entity.string_3
      let inv_id = entity.string_4
      let m_id =entity.message_id
      let type = entity.string_2
      if (status == "Draft" && inv_id !='' && type=='header') {
  
  await $flows.Dynamics365BusinessCentral.flow_message_status_update({m_id:m_id,inv_id:inv_id})
  
      }
  $grid.refresh    
  }
  $grid.refresh
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
