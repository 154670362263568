import { Inject, Injectable, Injector }from '@angular/core';

import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';
import { Materials_DatasourceService } from './Materials.datasource.index';
import { PalletTransactions_DatasourceService } from './PalletTransactions.datasource.index';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';
import { Lots_DatasourceService } from './Lots.datasource.index';
import { Owners_DatasourceService } from './Owners.datasource.index';
import { Discussions_DatasourceService } from './Discussions.datasource.index';
import { ExcelOrderImport_DatasourceService } from './ExcelOrderImport.datasource.index';
import { Document360_DatasourceService } from './Document360.datasource.index';
import { Usersnap_DatasourceService } from './Usersnap.datasource.index';
import { Locations_DatasourceService } from './Locations.datasource.index';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { Ultraship_DatasourceService } from './Ultraship.datasource.index';
import { ExcelInventoryImport_DatasourceService } from './ExcelInventoryImport.datasource.index';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { SalesOrders_DatasourceService } from './SalesOrders.datasource.index';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { Reports_DatasourceService } from './Reports.datasource.index';

import { app_atest_callService } from './app.datasource.index';
import { app_custom_ds_account_contact_lookupService } from './app.datasource.index';
import { app_custom_ds_carriers_ddService } from './app.datasource.index';
import { app_custom_ds_get_account_name_by_idService } from './app.datasource.index';
import { app_custom_ds_get_carrier_id_by_nameService } from './app.datasource.index';
import { app_custom_ds_get_orderLines_with_totals_by_orderIdService } from './app.datasource.index';
import { app_custom_ds_get_order_class_id_by_nameService } from './app.datasource.index';
import { app_custom_ds_get_pallet_detailsService } from './app.datasource.index';
import { app_custom_ds_get_project_lookup_by_nameService } from './app.datasource.index';
import { app_custom_ds_materials_udf_gridService } from './app.datasource.index';
import { app_custom_ds_order_class_ddService } from './app.datasource.index';
import { app_custom_ds_order_import_gridService } from './app.datasource.index';
import { app_custom_ds_projects_ddService } from './app.datasource.index';
import { app_custom_ds_udf_allergen_ddService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_DatasourceService);
    this.PrintNode = this.injector.get(PrintNode_DatasourceService);
    this.Materials = this.injector.get(Materials_DatasourceService);
    this.PalletTransactions = this.injector.get(PalletTransactions_DatasourceService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_DatasourceService);
    this.Lots = this.injector.get(Lots_DatasourceService);
    this.Owners = this.injector.get(Owners_DatasourceService);
    this.Discussions = this.injector.get(Discussions_DatasourceService);
    this.ExcelOrderImport = this.injector.get(ExcelOrderImport_DatasourceService);
    this.Document360 = this.injector.get(Document360_DatasourceService);
    this.Usersnap = this.injector.get(Usersnap_DatasourceService);
    this.Locations = this.injector.get(Locations_DatasourceService);
    this.Inventory = this.injector.get(Inventory_DatasourceService);
    this.Ultraship = this.injector.get(Ultraship_DatasourceService);
    this.ExcelInventoryImport = this.injector.get(ExcelInventoryImport_DatasourceService);
    this.Dynamics365BusinessCentral = this.injector.get(Dynamics365BusinessCentral_DatasourceService);
    this.Notifications = this.injector.get(Notifications_DatasourceService);
    this.Invoices = this.injector.get(Invoices_DatasourceService);
    this.SalesOrders = this.injector.get(SalesOrders_DatasourceService);
    this.FootPrintManager = this.injector.get(FootPrintManager_DatasourceService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_DatasourceService);
    this.Reports = this.injector.get(Reports_DatasourceService);
  }

    public Utilities: Utilities_DatasourceService;
    public PrintNode: PrintNode_DatasourceService;
    public Materials: Materials_DatasourceService;
    public PalletTransactions: PalletTransactions_DatasourceService;
    public PurchaseOrders: PurchaseOrders_DatasourceService;
    public Lots: Lots_DatasourceService;
    public Owners: Owners_DatasourceService;
    public Discussions: Discussions_DatasourceService;
    public ExcelOrderImport: ExcelOrderImport_DatasourceService;
    public Document360: Document360_DatasourceService;
    public Usersnap: Usersnap_DatasourceService;
    public Locations: Locations_DatasourceService;
    public Inventory: Inventory_DatasourceService;
    public Ultraship: Ultraship_DatasourceService;
    public ExcelInventoryImport: ExcelInventoryImport_DatasourceService;
    public Dynamics365BusinessCentral: Dynamics365BusinessCentral_DatasourceService;
    public Notifications: Notifications_DatasourceService;
    public Invoices: Invoices_DatasourceService;
    public SalesOrders: SalesOrders_DatasourceService;
    public FootPrintManager: FootPrintManager_DatasourceService;
    public FootPrintApiManager: FootPrintApiManager_DatasourceService;
    public Reports: Reports_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _atest_call: app_atest_callService;
  public get atest_call(): app_atest_callService {
    if(!this._atest_call) {
      this._atest_call = this.injector.get(app_atest_callService);
    }
    return this._atest_call;
  }
  private _custom_ds_account_contact_lookup: app_custom_ds_account_contact_lookupService;
  public get custom_ds_account_contact_lookup(): app_custom_ds_account_contact_lookupService {
    if(!this._custom_ds_account_contact_lookup) {
      this._custom_ds_account_contact_lookup = this.injector.get(app_custom_ds_account_contact_lookupService);
    }
    return this._custom_ds_account_contact_lookup;
  }
  private _custom_ds_carriers_dd: app_custom_ds_carriers_ddService;
  public get custom_ds_carriers_dd(): app_custom_ds_carriers_ddService {
    if(!this._custom_ds_carriers_dd) {
      this._custom_ds_carriers_dd = this.injector.get(app_custom_ds_carriers_ddService);
    }
    return this._custom_ds_carriers_dd;
  }
  private _custom_ds_get_account_name_by_id: app_custom_ds_get_account_name_by_idService;
  public get custom_ds_get_account_name_by_id(): app_custom_ds_get_account_name_by_idService {
    if(!this._custom_ds_get_account_name_by_id) {
      this._custom_ds_get_account_name_by_id = this.injector.get(app_custom_ds_get_account_name_by_idService);
    }
    return this._custom_ds_get_account_name_by_id;
  }
  private _custom_ds_get_carrier_id_by_name: app_custom_ds_get_carrier_id_by_nameService;
  public get custom_ds_get_carrier_id_by_name(): app_custom_ds_get_carrier_id_by_nameService {
    if(!this._custom_ds_get_carrier_id_by_name) {
      this._custom_ds_get_carrier_id_by_name = this.injector.get(app_custom_ds_get_carrier_id_by_nameService);
    }
    return this._custom_ds_get_carrier_id_by_name;
  }
  private _custom_ds_get_orderLines_with_totals_by_orderId: app_custom_ds_get_orderLines_with_totals_by_orderIdService;
  public get custom_ds_get_orderLines_with_totals_by_orderId(): app_custom_ds_get_orderLines_with_totals_by_orderIdService {
    if(!this._custom_ds_get_orderLines_with_totals_by_orderId) {
      this._custom_ds_get_orderLines_with_totals_by_orderId = this.injector.get(app_custom_ds_get_orderLines_with_totals_by_orderIdService);
    }
    return this._custom_ds_get_orderLines_with_totals_by_orderId;
  }
  private _custom_ds_get_order_class_id_by_name: app_custom_ds_get_order_class_id_by_nameService;
  public get custom_ds_get_order_class_id_by_name(): app_custom_ds_get_order_class_id_by_nameService {
    if(!this._custom_ds_get_order_class_id_by_name) {
      this._custom_ds_get_order_class_id_by_name = this.injector.get(app_custom_ds_get_order_class_id_by_nameService);
    }
    return this._custom_ds_get_order_class_id_by_name;
  }
  private _custom_ds_get_pallet_details: app_custom_ds_get_pallet_detailsService;
  public get custom_ds_get_pallet_details(): app_custom_ds_get_pallet_detailsService {
    if(!this._custom_ds_get_pallet_details) {
      this._custom_ds_get_pallet_details = this.injector.get(app_custom_ds_get_pallet_detailsService);
    }
    return this._custom_ds_get_pallet_details;
  }
  private _custom_ds_get_project_lookup_by_name: app_custom_ds_get_project_lookup_by_nameService;
  public get custom_ds_get_project_lookup_by_name(): app_custom_ds_get_project_lookup_by_nameService {
    if(!this._custom_ds_get_project_lookup_by_name) {
      this._custom_ds_get_project_lookup_by_name = this.injector.get(app_custom_ds_get_project_lookup_by_nameService);
    }
    return this._custom_ds_get_project_lookup_by_name;
  }
  private _custom_ds_materials_udf_grid: app_custom_ds_materials_udf_gridService;
  public get custom_ds_materials_udf_grid(): app_custom_ds_materials_udf_gridService {
    if(!this._custom_ds_materials_udf_grid) {
      this._custom_ds_materials_udf_grid = this.injector.get(app_custom_ds_materials_udf_gridService);
    }
    return this._custom_ds_materials_udf_grid;
  }
  private _custom_ds_order_class_dd: app_custom_ds_order_class_ddService;
  public get custom_ds_order_class_dd(): app_custom_ds_order_class_ddService {
    if(!this._custom_ds_order_class_dd) {
      this._custom_ds_order_class_dd = this.injector.get(app_custom_ds_order_class_ddService);
    }
    return this._custom_ds_order_class_dd;
  }
  private _custom_ds_order_import_grid: app_custom_ds_order_import_gridService;
  public get custom_ds_order_import_grid(): app_custom_ds_order_import_gridService {
    if(!this._custom_ds_order_import_grid) {
      this._custom_ds_order_import_grid = this.injector.get(app_custom_ds_order_import_gridService);
    }
    return this._custom_ds_order_import_grid;
  }
  private _custom_ds_projects_dd: app_custom_ds_projects_ddService;
  public get custom_ds_projects_dd(): app_custom_ds_projects_ddService {
    if(!this._custom_ds_projects_dd) {
      this._custom_ds_projects_dd = this.injector.get(app_custom_ds_projects_ddService);
    }
    return this._custom_ds_projects_dd;
  }
  private _custom_ds_udf_allergen_dd: app_custom_ds_udf_allergen_ddService;
  public get custom_ds_udf_allergen_dd(): app_custom_ds_udf_allergen_ddService {
    if(!this._custom_ds_udf_allergen_dd) {
      this._custom_ds_udf_allergen_dd = this.injector.get(app_custom_ds_udf_allergen_ddService);
    }
    return this._custom_ds_udf_allergen_dd;
  }
}

