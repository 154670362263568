import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';
import { BaseComponent } from './components/base.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Dynamics365BusinessCentral_ShellService, EModalSize, EToasterType, EToasterPosition } from './Dynamics365BusinessCentral.shell.service';
import { Dynamics365BusinessCentral_OperationService } from './Dynamics365BusinessCentral.operation.service';
import { Dynamics365BusinessCentral_DatasourceService } from './Dynamics365BusinessCentral.datasource.index';
import { Dynamics365BusinessCentral_FlowService } from './Dynamics365BusinessCentral.flow.index';
import { Dynamics365BusinessCentral_ReportService } from './Dynamics365BusinessCentral.report.index';
import { Dynamics365BusinessCentral_LocalizationService } from './Dynamics365BusinessCentral.localization.service';
import { Language } from './localization.service';
import { CleanupLoggerService } from './cleanup.logging.service';
import { $frontendTypes} from './Dynamics365BusinessCentral.frontend.types'
import { $frontendTypes as $types} from './Dynamics365BusinessCentral.frontend.types' 


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Dynamics365BusinessCentral-number_of_errors_widget',
  templateUrl: './Dynamics365BusinessCentral.number_of_errors_widget.component.html'
})
export class Dynamics365BusinessCentral_number_of_errors_widgetComponent extends BaseComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { application_name?: string, customerr?: string, refresh_control?: boolean } = { application_name: null, customerr: null, refresh_control: null };
  //#region Inputs
  @Input('application_name') set $inParams_application_name(v: string) {
    this.inParams.application_name = v;
  }
  get $inParams_application_name(): string {
    return this.inParams.application_name;
  }
  @Input('customerr') set $inParams_customerr(v: string) {
    this.inParams.customerr = v;
  }
  get $inParams_customerr(): string {
    return this.inParams.customerr;
  }
  @Input('refresh_control') set $inParams_refresh_control(v: boolean) {
    this.inParams.refresh_control = v;
  }
  get $inParams_refresh_control(): boolean {
    return this.inParams.refresh_control;
  }
  //#endregion Inputs

  //#region Variables
  vars: { value?: number, v1?: number, v2?: number } = { };
  //#endregion  
  private entity: { value?: number };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Dynamics365BusinessCentral_ShellService,
private datasources: Dynamics365BusinessCentral_DatasourceService,
private flows: Dynamics365BusinessCentral_FlowService,
private reports: Dynamics365BusinessCentral_ReportService,
private localization: Dynamics365BusinessCentral_LocalizationService,
private operations: Dynamics365BusinessCentral_OperationService,
private logger: CleanupLoggerService,
) { 
    super();

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;
  $hasDataLoaded = false;

  async $init() {
    await this.on_init();

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      customerr:  $widget.inParams.customerr ,
      application_name:  $widget.inParams.application_name 
    };

    const data = await this.datasources.Dynamics365BusinessCentral.ds_get_errors.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = $widget.vars.value;

    await this.on_data_loaded();
    
    this.originalValue = $widget.vars.value;
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $widget: Dynamics365BusinessCentral_number_of_errors_widgetComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  
  
  
  let n= (await $datasources.Dynamics365BusinessCentral.ds_get_errors.get({})).result
  let nn =n?.value.toString() ?? '0'
  
  
  if (nn.toString() != '0') {$widget.styles.setBadClass() }
  else
  {$widget.styles.setGoodClass() }
    
      
  
  
  
  $widget.vars.v1= n.value
  $widget.vars.value= $widget.vars.v1
  
  
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $widget: Dynamics365BusinessCentral_number_of_errors_widgetComponent,
  
    $shell: Dynamics365BusinessCentral_ShellService,
    $datasources: Dynamics365BusinessCentral_DatasourceService,
    $flows: Dynamics365BusinessCentral_FlowService,
    $reports: Dynamics365BusinessCentral_ReportService,
    $settings: SettingsValuesService,
    $operations: Dynamics365BusinessCentral_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Dynamics365BusinessCentral_LocalizationService,
    $event: any
  ) {
  let n= (await $datasources.Dynamics365BusinessCentral.ds_get_errors.get({})).result
  let nn =n?.value.toString() ?? '0'
  
  
  if (nn.toString() != '0') {$widget.styles.setBadClass()}
  else 
  {$widget.styles.setGoodClass()}
  
      
      
  
  
  
  
  $widget.vars.v2= n.value
  $widget.vars.value= $widget.vars.v2
  }
  //#endregion private flows
}
